@tailwind base;
@import "preflight.css";
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    @apply tw-text-4xl;
  }

  h2 {
    @apply tw-text-3xl;
  }

  h3 {
    @apply tw-text-2xl;
  }

  h4 {
    @apply tw-text-lg;
  }

  h5 {
    @apply tw-text-sm;
  }

  h6 {
    @apply tw-text-xs;
  }

  a {
    @apply tw-text-blue-light;
  }

  a:hover {
    @apply tw-underline
  }
}

.side-nav {
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  min-height: 100%;
  z-index: 1001;

  a:hover {
    text-decoration: none;
  }
}

.tailwind-content {
  max-height: calc(100% - 46px);

  &.tailwind-alert {
    max-height: calc(100% - 92px);
  }
}

.menu-icon {
  min-width: 50px;
  padding-right: 3px;

}

.w-50px {
  width: 50px;
}

.-r-50px {
  right: -50px;
}

.h-15 {
  height: 59px;
}

.h-46px {
  height: 46px;
}

.menu-icon {
  min-width: 50px;
  @apply w-50px tw-flex tw-justify-center tw-items-center h-15
}

.menu-item {
  overflow: hidden;
  transition: padding;
  @apply tw-bg-blue-cornflower-light tw-capitalize tw-no-underline tw-rounded tw-cursor-pointer tw-min-w-full  tw-duration-500 hover:tw-pl-1 tw-border-gray-dark tw-border-b hover:tw-w-64 hover:tw-text-white tw-flex w-50px tw-text-gray-300 tw-text-lg;

  a {
    @apply tw-text-gray-300 hover:tw-text-white
  }

  .menu-content {
    @apply tw-flex tw-relative tw-flex-1 tw-duration-500 tw-pl-2 tw-items-center tw-no-underline tw-text-sm;
    &.overlay {
      @apply tw-cursor-default tw-pl-0;
      .overlay-content {
        @apply tw-rounded-sm;
      }
    }
  }

  &:hover {
    @apply tw-bg-blue-cornflower-dark;
    overflow: visible;

    .menu-content {
      visibility: visible;
      overflow: visible;
    }

    .overlay {
      margin-left: -4px;

      .overlay-content {
        @apply tw-bg-blue-cornflower-dark;
      }
    }
  }
}

.selected {
  .menu-item {
    border-left: 3px solid white;
    @apply tw-bg-blue-cornflower-dark tw-text-white;

    .menu-content {
      margin-left: -7px;
    }

  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.rs-message-info {
  border-width: 1px;
  border-color: #bce8f1;

  .rs-message-content {
    .rs-message-body {
      color: #31708f !important;
    }
  }
}

.rs-message-success {
  border-width: 1px;
  border-color: #d0eccf;

  .rs-message-content {
    .rs-message-body {
      color: #3c763d !important;
    }
  }
}

.rs-message-warning {
  border-width: 1px;
  border-color: #faebcc;

  .rs-message-content {
    .rs-message-body {
        color: #8a6d3b !important;
    }
  }
}

.rs-message-error {
  border-width: 1px;
  border-color: #ebccd1;

  .rs-message-content {
    .rs-message-body {
      color: #a94442 !important;
    }
  }
}
